import tw from "tailwind.macro"
import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

const Wrapper = tw.div`
  flex items-center justify-center flex-col h-screen
`

const Main = tw.div`
  p-6 bg-gray-100 rounded-lg shadow-2xl
`

const Heading = tw.h1`
  text-2xl text-gray-500 uppercase
`

const Text = tw.p`
  text-xl text-gray-700
`

export default () => (
  <Wrapper>
    <SEO title="Page not found (404)" />
    <Main>
      <Heading>Page not found</Heading>
      <Text>
        <Link to="/">Return to home</Link>.
      </Text>
    </Main>
  </Wrapper>
)
